import React from "react";

import PageLayout from "layouts/Page";

import SEO from "components/SEO";

export default () => (
  <PageLayout>
    <SEO title="Support" />
    <div className="w-full max-w-2xl px-4 mx-auto">
      <h1>Trouble using Ikeetmee?</h1>
      <p>
        Please send your feedback in this{" "}
        <a
          className="text-primary"
          href="https://forms.gle/ma6CjcHmrJSAGP6AA"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google form
        </a>
        , or reach out to me personally via{" "}
        <a
          className="text-primary"
          href="mailto:wouterraateland@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          email
        </a>{" "}
        or{" "}
        <a
          className="text-primary"
          href="https://twitter.com/wouterraateland"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
      </p>
    </div>
  </PageLayout>
);
